const caseManagementReportOptions = [
  // { label: "Cases per Case worker and project", value: "Cases per Case worker and project" },
  // { label: "Actions per Case Worker and project", value: "Actions per Case Worker and project" },
  // { label: "Open cases per project", value: "Open cases per project" },
  // { label: "Requests per project", value: "Requests per project" },
  // { label: "Requests per request status", value: "Requests per request status" },
  // { label: "Projects per Programme", value: "Projects per Programme" },
  // { label: "Donations Details", value: "Donations Details" },
  // { label: "Prescreenings by Case Worker", value: "Prescreenings by Case Worker" },
  // { label: "Prescreenings by Case Worker and Action Taken", value: "Prescreenings by Case Worker and Action Taken" },
  { label: "Total number of Social Intake interviews by Case Worker", value: "Total number of Social Intake interviews by Case Worker" },
  // { label: "Total number of Social Intake interviews by Case Worker & Project", value: "Total number of Social Intake interviews by Case Worker & Project" },
  // { label: "Unique beneficiaries by Case Status & Project", value: "Unique beneficiaries by Case Status & Project" },
  // { label: "Unique beneficiaries by Case Worker", value: "Unique beneficiaries by Case Worker" },
  // { label: "Unique beneficiaries by Case Worker and Case Status", value: "Unique beneficiaries by Case Worker and Case Status" },
  // { label: "Unique beneficiaries by Case Worker , Case Status and Project", value: "Unique beneficiaries by Case Worker , Case Status and Project" },
  // { label: "Total actions by Case Worker , Case Status and Service", value: "Total actions by Case Worker , Case Status and Service" },
  // { label: "Total actions by Case Worker , Case Status, Service and Project", value: "Total actions by Case Worker , Case Status, Service and Project" },
  // { label: "Unique beneficiaries by Case Worker , Service ,Request", value: "Unique beneficiaries by Case Worker , Service ,Request" },
  { label: "Unique beneficiaries by Case Status", value: "A001"},
  // { label: "Unique beneficiaries by Case Worker and Service with request", value:"A002"},
  { label: "Unique beneficiaries by Case Worker , Service ,Request,Project", value:"A003"},
  // { label: "Unique beneficiaries by Case Worker and Service", value:"A004"},
  // { label: "Unique beneficiaries by Case Worker and Service, Requests ,Next Steps", value:"A005"},
  // { label: "Unique beneficiaries by Case Worker and Project, Service, Requests ,Next Steps", value:"A006"},
  { label: "Total requests by Case Worker , Case Status and Service", value:"A007"},
  // { label: "Total requests by Case Worker , Case Status, Service and Project", value:"A008"},
  // { label: "Unique beneficiaries by Case Worker and non session based Service", value:"A009"},
  // { label: "Unique beneficiaries by Case Worker, Project and non session based Service", value:"A010"},
  // { label: "Unique beneficiaries by Case Worker and Legal Case", value:"A011"},
  // { label: "Unique beneficiaries by Case Worker and Project, Legal Case", value:"A012"},
];


const extractionReportOptions = [
  // { label: "Legal Cases", value: "E001" },
  { label: "Follow up request and status", value: "E002"},
  // { label: "Internal Referals", value: "E003"},
  // { label: "Scheduled Legal Representations", value: "E004"},
  // { label: "Next Steps Referals", value: "E005"},
  // { label: "Scheduled sessions", value: "E006"},
  // { label: "Next Steps", value: "E007"},
  // { label: "Legal Non - session based", value: "E008"},
  // { label: "Session based ActionsExtractions", value: "E009"},
  // { label: "Strategic Communication actions  for all projects", value: "E010"},
  // { label: "Outreach by Communication Types ", value: "E011"},
  // { label: "Extraction of participants in Group and Community Activities", value: "E013"},
  // { label: "Extraction of participants in Group and Community Activities 2", value: "E014"},
  { label: "Beneficiaries List for request or acceptance of employment", value: "E015"},
  { label: "Extraction List with referral name and information if benefit is provided", value: "E016"},
  { label: "Extraction List of Household & number of family members per Household", value: "E017"},
  { label: "EBeneficiaries List by age,gender,Nationality, Language and and Employment information", value: "E018"},

];

const communicationReportOptions = [
  { label: "per Project", value: "per Project" },
  { label: "Total number of Communication Types", value: "total communications"},
  { label: "Total number of Thematic Categories", value: "total thematic"},
  { label: "Total number of Thematic Categories by Project", value: "total thematic project"},
  { label: "Totals of Outreach Metric", value: "total metrics"},
  { label: "Totals of Outreach Metric by Communication Type & Outreach Metric", value: "total communication metric"},
  { label: "Totals of Outreach Metric by Communication Type, Outreach Metric & Project", value: "total communication metric project"},
];

const communityActivitiesReportOptions = [
  { label: "per type", value: "per type" },
  { label: "per project", value: "per project" },
  { label: "Activity Sessions by type", value: "activity sessions"},
  { label: "Activity Sessions by type & project", value: "activity sessions project"},
  { label: "Activity Sessions by type and activity", value: "activity sessions name"},
  { label: "Activity Sessions by type and activity & project", value: "activity sessions name project"},
];

const prescreeningReportOptions = [
  { label: "Prescreenings per Gender", value: "gender" },
  { label: "Prescreenings per Location", value: "location" },
  { label: "Prescreenings per Age", value: "age" },
  { label: "Prescreenings per Legal Status", value: "legalstatus" },
  { label: "Prescreenings per Vulnerabilities", value: "vulnerabilities" },
  { label: "Prescreenings per Action Taken", value: "actiontaken" }
];

const socialIntegrationReportOptions = [
  // { label: "Unique Households", value: "unique households" },
  // { label: "Unique Households By project", value: "unique households projects" },
  // { label: "Unique Beneficiaries by Case Status", value: "unique beneficiaries case"},
  // { label: "Unique Beneficiaries by Gender,Age & Legal Status", value: "unique beneficiaries gender age legal"},
  // { label: "Unique Beneficiaries by Gender", value: "unique beneficiaries gender"},
  // { label: "Unique Beneficiaries by Age", value: "unique beneficiaries age"},
  // { label: "Unique Beneficiaries by Legal Status", value: "unique beneficiaries legal"},
  // { label: "Unique Beneficiaries by Vulnerabilities", value: "unique beneficiaries vulnerability"},
  // { label: "Unique Beneficiaries by Project", value: "unique beneficiaries project"},
  // { label: "Unique beneficiaries by Request", value: "unique beneficiaries request"},
  // { label: "Unique beneficiaries by Request age,gender,LegalStatus", value: "unique beneficiaries request age,gender,status"},
  // { label: "Unique beneficiaries by Request age,gender,LegalStatus & Project", value: "unique beneficiaries request age,gender,status,project"},
  // { label: "Unique beneficiaries by Request and Request Status", value: "unique beneficiaries reqest status"},
  // { label: "Unique beneficiaries by Request and Request Status and Project", value: "unique beneficiaries reqest status project"},
  // { label: "Unique beneficiaries by Non-Session Service", value: "unique beneficiaries non service"},
  // { label: "Unique beneficiaries by Non-Session Service age,gender,LegalStatus", value: "unique beneficiaries non service age gender status"},
  // { label: "Unique beneficiaries by Non-Session Service age,gender,LegalStatus & Project", value: "unique beneficiaries non service age gender status project"},
  // { label: "Unique beneficiaries by Non-Session Service & Vulnerability", value: "unique beneficiaries non service vulnerability"},
  // { label: "Unique beneficiaries by Non-Session Service Vulnerability & Project", value: "unique beneficiaries non service vulnerability project"},
  // { label: "Unique beneficiaries by Next Step and Status Request", value: "next step request status"},
  // { label: "Unique beneficiaries by Next Step, age & Status Request", value: "next step request status age"},
  // { label: "Unique beneficiaries by Next Step, gender & Status Request", value: "next step request status gender"},
  // { label: "Unique beneficiaries by Next Step, LegalStatus & Status Request", value: "next step request status legal"},
  // { label: "Unique beneficiaries by Requests ,Next Steps, age,gender,LegalStatus", value: "next step request status age gender legal"},
  // { label: "Unique beneficiaries by Requests ,Next Steps, age", value: "next step request age"},
  // { label: "Unique beneficiaries by Requests ,Next Steps, gender", value: "next step request gender"},
  // { label: "Unique beneficiaries by Requests ,Next Steps, Legal Status", value: "next step request legal"},
  // { label: "Unique beneficiaries by Requests age, gender, Legal Status, Next Step & Project", value: "next step request age gender legal project"},
  // { label: "Unique beneficiaries by NextStep & Project", value: "next step project"},
  // { label: "Unique beneficiaries by Requests, Follow Up", value: "follow up request"},
  // { label: "Unique beneficiaries by Requests, Follow Up, age, gender, Legal Status", value: "follow up request age gender legal"},
  // { label: "Unique beneficiaries by Requests, Follow Up, age, gender, Legal Status & Project", value: "follow up request age gender legal project"},
  // { label: "Total sessions by Service",value:"C001" },
  // { label: "Total sessions by Service,Reques",value:"C002" },
  // { label: "Unique number of companies reached",value:"C003" },
  // { label: "Unique number of companies reached by Type of Action",value:"C004" },
  // { label: "Unique number of companies reached by project",value:"C005" },
  // { label: "Unique number of companies reached by project and actionType",value:"C006" },
  // { label: "Total PDPs",value:"C007" },
  // { label: "Total beneficiaries with PDP by projects",value:"C008" },
  // { label: "Total Intakes",value:"C009" },
  // { label: "Total Intakes by projects",value:"C010" },
  // { label: "Total Intakes by projects",value:"C011" },
  // { label: "Total Intakes by project and intake category",value:"C012" },
  // { label: "Average num of Intake participation",value:"C013" },
  // { label: "Unique beneficiaries with referrals",value:"C014" },
  // { label: "Unique beneficiaries by service with referrals",value:"C015" },
  { label: "Unique beneficiaries by Requests with referrals",value:"C016" },
  { label: "Unique beneficiaries by Requests age,gender,LegalStatus with referrals",value:"C017" },
  { label: "Unique beneficiaries by Requests age,gender,LegalStatus & Project with referrals",value:"C018" },
  { label: "Unique beneficiaries by Requests with referrals", value:"B011"},
  { label: "Distinct Households & Beneficiaries", value:"B014"},
  { label: "Unique beneficiaries by age", value:"B015"},
  { label: "Unique beneficiaries by Gender", value:"B016"},
  { label: "Unique beneficiaries by Nationality", value:"B017"},
  { label: "Total Households and total number of household members", value:"B018"},
  { label: "Total Households and total number of household members by Family Type", value:"B019"},
];

const legalServicesReportOptions = [
  // { label: "# of ongoing cases", value: "ongoing cases"},
  // { label: "Count Legal Cases by purpose,request,request status", value: "legal cases purpose,request"},
  // { label: "Count Legal Cases by purpose,request,request status & Project", value: "legal cases project,purpose,request"},
  // { label: "Sessions by Legal Cases", value: "legal cases sessions"},
  // { label: "Sessions by Legal Cases & Project", value: "legal cases project sessions"},
  // { label: "# of Requests by legal case by project", value:"B001"},
  // { label: "Unique beneficiaries by Legal Case", value:"B002"},
  // { label: "Unique beneficiaries by Legal Case,Requests age,gender,LegalStatus", value:"B003"},
  // { label: "Unique beneficiaries by Legal Case,Requests age,gender,LegalStatus & Project", value:"B004"},
  // { label: "Beneficiaries by Legal Case and vulnerability", value:"B005"},
  // { label: "Beneficiaries by Legal Case , vulnerability and project", value:"B006"},
  // { label: "Total sessions by Service", value:"B007"},
  // { label: "Total sessions by Service,Request", value:"B008"},
  // { label: "Unique beneficiaries with referrals", value:"B009"},
  // { label: "Unique beneficiaries by service with referrals", value:"B010"},
  { label: "Unique beneficiaries by Requests with referrals", value:"B011"},
  // { label: "Unique beneficiaries by Requests age,gender,LegalStatus with referrals", value:"B012"},
  //{ label: "Distinct Households & Beneficiaries", value:"B013"},
  { label: "Distinct Households & Beneficiaries", value:"B014"},
  { label: "Unique beneficiaries by age", value:"B015"},
  { label: "Unique beneficiaries by Gender", value:"B016"},
  { label: "Unique beneficiaries by Nationality", value:"B017"},
  { label: "Total Households and total number of household members", value:"B018"},
  { label: "Total Households and total number of household members by Family Type", value:"B019"},
];

export {
  caseManagementReportOptions,
  communicationReportOptions,
  extractionReportOptions,
  communityActivitiesReportOptions,
  legalServicesReportOptions,
  prescreeningReportOptions,
  socialIntegrationReportOptions
};
