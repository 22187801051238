































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class DatePicker extends Vue {
  @Prop()
  public value: any;

  @Prop()
  public disable!: boolean;

  private valueInternal: any = null;

  dateOrNull(v: string) {
    return !v || /^-?[\d]+-[0-1]\d-[0-3]\d$/.test(v) || "Wrong format";
  }

  created() {
    if (!this.value && process.env.NODE_ENV === "development") {
      this.valueInternal = new Date().toISOString().split("T")[0];
    }
  }
  @Watch("value", { immediate: true })
  onValueChanged() {
    if (this.value) {
      this.valueInternal = this.value.split("T")[0];
    }
  }

  @Watch("valueInternal", { immediate: true })
  onValueInternalChanged() {
    this.$emit("input", this.valueInternal);
  }
}
