function createReportHeaders(data: any) {
    const columns = [];
    for (let i = 0; i < data.columns.length; i++) {
      columns.push({
        name: data.columns[i],
        required: true,
        label: data.columns[i],
        align: "left",
        sortable: true,
        field: (x: any) => x[Object.keys(x)[i]]
      });
    }
    return columns;
}

export { createReportHeaders }
